import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { ProcessTwoEndPointProxy } from '@/modules/processTwoEndPoint/components/ProcessTwoEndPointProxy';
import { ProcessTwoEndPointRenderer } from '@/modules/processTwoEndPoint/components/ProcessTwoEndPointRenderer';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint/types';
import { SHAPE_TO_CANVAS_SCALE } from '@modules/workspace/helpers/konva';
import shapeAtom from '@recoil/shape/atom';

type ProcessTwoEndPointProps = {
  id: string;
};

const ProcessTwoEndPointComponent = ({ id }: ProcessTwoEndPointProps) => {
  const processTwoEp = useRecoilValue(shapeAtom(id)) as ProcessTwoEPShape;

  if (!processTwoEp.id) return null;

  return (
    <>
      <ProcessTwoEndPointRenderer
        id={id}
        points={processTwoEp.properties.controlPoints}
        width={processTwoEp.parameters.width * SHAPE_TO_CANVAS_SCALE}
        supportedVehicleIds={processTwoEp.parameters.supportedVehicleIds}
      />
      <ProcessTwoEndPointProxy
        id={id}
        points={processTwoEp.properties.controlPoints}
        width={processTwoEp.parameters.width}
      />
    </>
  );
};

export const ProcessTwoEndPoint = memo(ProcessTwoEndPointComponent);
