import { ShapeType } from '@/modules/common/types/shapes';
import { KEYCODE } from '@/store/recoil/input';
import { ToolMenuItem } from '@/store/recoil/tool/types';

export const buildingMenuItems: ToolMenuItem[] = [
  {
    type: 'tool',
    id: ShapeType.WALL,
    tooltipLabel: 'toolbar.walls_wall_button.tooltip',
    keycode: KEYCODE.W,
    icon: ShapeType.WALL,
  },
  {
    type: 'tool',
    id: ShapeType.OBSTACLE,
    tooltipLabel: 'toolbar.obstacles_button.tooltip',
    keycode: KEYCODE.O,
    icon: ShapeType.OBSTACLE,
  },
];

export const areaMenuItems: ToolMenuItem[] = [
  {
    type: 'tool',
    id: ShapeType.INTAKE,
    tooltipLabel: 'toolbar.areas_intake_button.tooltip',
    keycode: KEYCODE.I,
    icon: ShapeType.INTAKE,
  },
  {
    type: 'tool',
    id: ShapeType.STORAGE,
    tooltipLabel: 'toolbar.areas_storage_button.tooltip',
    keycode: KEYCODE.S,
    icon: ShapeType.STORAGE,
  },
  {
    type: 'tool',
    id: ShapeType.PARKING,
    tooltipLabel: 'toolbar.areas_parking_button',
    keycode: KEYCODE.P,
    icon: ShapeType.PARKING,
  },
  {
    type: 'tool',
    id: ShapeType.CHARGING,
    tooltipLabel: 'toolbar.areas_charging_button.tooltip',
    keycode: KEYCODE.C,
    icon: ShapeType.CHARGING,
  },
  {
    type: 'tool',
    id: ShapeType.DELIVERY,
    tooltipLabel: 'toolbar.areas_delivery_button.tooltip',
    keycode: KEYCODE.D,
    icon: ShapeType.DELIVERY,
  },
  {
    type: 'menu',
    label: 'toolbar.areas_process_button',
    icon: 'process',
    items: [
      {
        type: 'tool',
        id: ShapeType.PROCESS_ONE_EP,
        tooltipLabel: 'toolbar.areas_process_one_end_point_button',
        icon: null,
      },
      {
        type: 'tool',
        id: ShapeType.PROCESS_TWO_EP,
        tooltipLabel: 'toolbar.areas_process_two_end_points_button',
        icon: null,
        disabled: true
      },
    ],
  },
  {
    type: 'tool',
    id: ShapeType.HANDOVER,
    tooltipLabel: 'toolbar.areas_handover_button',
    disabled: true,
    icon: ShapeType.HANDOVER,
  },
];

export const roadMenuItems: ToolMenuItem[] = [
  {
    type: 'tool',
    id: ShapeType.HIGHWAY,
    tooltipLabel: 'toolbar.highway_button.tooltip',
    keycode: KEYCODE.SHIFT_H,
    icon: ShapeType.HIGHWAY,
  },
  {
    type: 'tool',
    id: ShapeType.HIGHWAY_ANGLED,
    tooltipLabel: 'toolbar.highway_angled_button.tooltip',
    keycode: KEYCODE.U,
    icon: ShapeType.HIGHWAY_ANGLED,
  },
];

export const templateMenuItems: ToolMenuItem[] = [
  {
    type: 'tool',
    id: ShapeType.LASSIE,
    tooltipLabel: 'toolbar.lassie_button.tooltip',
  },
];
