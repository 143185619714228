import { allConnectionsSelector } from '@modules/connections/connections';
import { allDistantConnectionsSelector } from '@modules/connections/distant';
import { useRecoilCallback } from 'recoil';

import { layoutFlowsSelector } from '../../../flows/store/layout';
import { allGroupsSelector } from '../../../shapeGroups';
import { getDisconnectedFlowStopIds } from '../helpers';
import { prevalidationState } from '../store';

export const useDisconnectedFlowStopIdsValidator = () => {
  const validateDisconnectedFlowStopIds = useRecoilCallback(
    ({ snapshot, set }) =>
      async (syncResultsWithStore = true) => {
        const [flows, allConnections, allDistantConnections, allShapeGroups] = await Promise.all([
          snapshot.getPromise(layoutFlowsSelector),
          snapshot.getPromise(allConnectionsSelector),
          snapshot.getPromise(allDistantConnectionsSelector),
          snapshot.getPromise(allGroupsSelector),
        ]);

        const disconnectedFlowStopIds = getDisconnectedFlowStopIds(
          flows,
          allConnections,
          allDistantConnections,
          allShapeGroups,
        );

        if (syncResultsWithStore) {
          set(prevalidationState, (current) => {
            if (
              current.disconnectedFlowStopIds.length === 0 &&
              disconnectedFlowStopIds.length === 0
            ) {
              return current;
            }

            return {
              ...current,
              disconnectedFlowStopIds,
            };
          });
        }

        return disconnectedFlowStopIds;
      },
    [],
  );

  return {
    validateDisconnectedFlowStopIds,
  };
};
