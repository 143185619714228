import { Vector2 } from 'three';
import { v4 as uuid } from 'uuid';

import { getSegments } from '@/modules/angledHighways/helpers';
import { boundingBoxToLine } from '@/modules/common/helpers/boundingBox';
import { doPolygonsIntersect, lineToPolygon, makeOffsetPoly } from '@/modules/common/helpers/polygon';
import { Crossing } from '@/modules/common/types/connections';
import { HighwayShape } from '@/store/recoil/shape';
import { AngledHighwayShape, LineSegment, WideLineSegment } from '@modules/angledHighways/types';
import { ShapeType } from '@modules/common/types/shapes';
import {
  lineSegmentIntersect,
} from '@modules/connections/common/helpers';
import { areLinesParallel } from '@modules/workspace/helpers/shape';
import { primitiveArraysHaveEqualContents } from '@/modules/common/helpers/array';

export type CrossingPosition = {
  from: string;
  to: string;
  position: Vector2;
};

const POLYGON_OUTSET_SIZE = -50; // minus to make object bigger

export const findCrossingsPositions = <T extends AngledHighwayShape | HighwayShape>(
  highway: T,
  roads: T[],
): CrossingPosition[] => {
  const positions: CrossingPosition[] = [];

  // find overlap from other highway to this highway
  // eslint-disable-next-line no-restricted-syntax
  for (const otherHighway of roads) {
    if (highway.id === otherHighway.id) continue;

    if (
      highway.parameters.supportedVehicleIds.length > 0 &&
      !primitiveArraysHaveEqualContents(highway.parameters.supportedVehicleIds, otherHighway.parameters.supportedVehicleIds)
    ) continue 

    const position = findCrossingPositions(highway, otherHighway);
    if (position) {
      positions.push(...position);
    }
  }

  return positions;
};

export const findCrossingPositions = <T extends AngledHighwayShape | HighwayShape>(
  highway1: T,
  highway2: T,
): CrossingPosition[] => {
  if (highway1.id === highway2.id) return null;

  const shapeA =
    highway1.type === ShapeType.HIGHWAY_ANGLED
      ? getSegments(highway1)
      : [boundingBoxToLine(highway1.properties)];

  const shapeB =
    highway2.type === ShapeType.HIGHWAY_ANGLED
      ? getSegments(highway2)
      : [boundingBoxToLine(highway2.properties)];

  const computedCrossings: CrossingPosition[] = [];
  for (let indexA = 0; indexA < shapeA.length; indexA++) {
    const subShapeA = shapeA[indexA];

    for (let indexB = 0; indexB < shapeB.length; indexB++) {
      const subShapeB = shapeB[indexB];

      // highways in same directions doesn't create crossings, see connections
      if (!areLinesParallel(subShapeA.points, subShapeB.points)) {
        const position = calculateCrossingPositionUsingPolygon(subShapeA, subShapeB);
        if (position) {
          computedCrossings.push({
            from: highway1.id,
            to: highway2.id,
            position,
          });
        }
      }
    }
  }
  return computedCrossings;
};

const calculateCrossingPositionUsingPolygon = (
  lineA: WideLineSegment,
  lineB: WideLineSegment,
): Vector2 | null => {
  const polygonA = lineToPolygon(lineA);
  const polygonB = lineToPolygon(lineB);
  const polygonAOffset = makeOffsetPoly(polygonA, POLYGON_OUTSET_SIZE);
  const polygonBOffset = makeOffsetPoly(polygonB, POLYGON_OUTSET_SIZE);

  if (!doPolygonsIntersect(polygonAOffset, polygonBOffset)) return null;
  const position = calculateCrossingPositionUsingCenterLine(lineA, lineB);
  if (!position) {
    return null;
  }

  return new Vector2(position.x, position.y);
};

const calculateCrossingPositionUsingCenterLine = (
  lineA: WideLineSegment,
  lineB: WideLineSegment,
): Vector2 | null => {
  const segment1: LineSegment = {
    start: lineA.points.start,
    end: lineA.points.end,
  };
  const segment2: LineSegment = {
    start: lineB.points.start,
    end: lineB.points.end,
  };
  return lineSegmentIntersect(segment1, segment2, true);
};

export const crossingPositionsToCrossings = (computedCrossings: CrossingPosition[]): Crossing[] =>
  computedCrossings.map(
    (cc): Crossing => ({
      id: uuid(),
      from: cc.from,
      to: cc.to,
      position: cc.position,
    }),
  );
