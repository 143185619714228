import { Connection } from '@modules/common/types/connections';
import {
  DTShapePersisted,
  Connection as ConnectionPersisted,
} from '@modules/common/types/floorPlan';
import { DTShape } from '@modules/common/types/shapes';
import { sortConnections } from '../../common/helpers';
import { findConnectionPosition } from './connections';

/**
 * Prepares connections state to be loaded into the app.
 */
export const prepareToLoad = (
  connections: ConnectionPersisted[],
  shapes: DTShapePersisted[],
): Connection[] => {
  const shapesMap = new Map<string, DTShapePersisted>();
  shapes.forEach((item) => shapesMap.set(item.id, item));

  const newConnections: Connection[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (let connection of connections) {
    const fromShape = shapesMap.get(connection.from);
    const toShape = shapesMap.get(connection.to);
    if (!fromShape?.properties || !toShape?.properties) continue;

    const fromShapeDT: DTShape = {
      ...fromShape,
      isDrawing: false,
      isTransforming: false,
      isLoading: false,
      disabled: false,
    };

    const toShapeDT: DTShape = {
      ...toShape,
      isDrawing: false,
      isTransforming: false,
      isLoading: false,
      disabled: false,
    };

    const position = findConnectionPosition(fromShapeDT, toShapeDT);
    if (!position) continue;

    newConnections.push({
      ...connection,
      ...position,
      usePivots: !!connection.usePivots,
    });
  }

  return newConnections;
};

/**
 * Prepares connections state to be saved
 */
export const prepareToSave = (connection: Connection[]): ConnectionPersisted[] => {
  const connections = connection.map((distantConnection) => ({
    from: distantConnection.from.split('.')[0],
    to: distantConnection.to.split('.')[0],
    inChecked: distantConnection.inChecked,
    outChecked: distantConnection.outChecked,
    usePivots: distantConnection.usePivots,
    id: distantConnection.id,
  }));

  return sortConnections(connections);
};
