import { useTranslation } from 'react-i18next';
import { Group, Rect } from 'react-konva';

import { InvalidShape } from '@modules/workspace/components';
import { useColors } from '@modules/workspace/hooks';
import { PositionShape } from '@recoil/shape';
import { usePositionRenderer } from './hooks';

type Props = {
  shapeId: string;
  shapeState: PositionShape;
};

export function PositionRenderer({ shapeId, shapeState }: Props) {
  const { propertiesCanvas, loadBox, vehicleBox, hasLoadBox, error } =
    usePositionRenderer(shapeState);
  const { t } = useTranslation(['errors']);
  const { laneColor, loadBoxColor, shapeColor } = useColors(shapeId);

  if (!propertiesCanvas || !loadBox || !vehicleBox) return null;

  return (
    <Group x={propertiesCanvas.x} y={propertiesCanvas.y} rotation={propertiesCanvas.r}>
      {!error ? (
        <Rect
          height={propertiesCanvas.height}
          width={propertiesCanvas.width}
          fill={shapeColor}
          listening={false}
          strokeEnabled={false}
        />
      ) : propertiesCanvas.width !== 0 || propertiesCanvas.height !== 0 ? (
        <InvalidShape
          height={propertiesCanvas.height}
          width={propertiesCanvas.width}
          message={t(error.i18nMessageKey)}
        />
      ) : null}

      <Rect
        x={vehicleBox.x}
        y={vehicleBox.y}
        width={vehicleBox.width}
        height={vehicleBox.height}
        fill={laneColor}
        listening={false}
        strokeEnabled={false}
      />

      {hasLoadBox && (
        <Rect
          x={loadBox.x}
          y={loadBox.y}
          width={loadBox.width}
          height={loadBox.height}
          fill={loadBoxColor}
          listening={false}
          strokeEnabled={false}
        />
      )}
    </Group>
  );
}
