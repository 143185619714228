import { StyledTooltip } from '@/modules/common/components/StyledTooltip';
import { toolButtonState } from '@/store/recoil/tool';
import { Button, IconButton } from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { ToolAtom } from '../../store/recoil/tool/atom';
import getIcon from './getIcons';

type ToolButtonProps = {
  type: ToolAtom;
  regularBtn?: boolean;
  disabled?: boolean;
  onSelect?: (tool: ToolAtom, icon?: string) => void;
  tooltipLabel?: ReactNode;
  icon?: string;
};

export const ToolButton: React.FC<ToolButtonProps> = ({
  type,
  disabled,
  regularBtn,
  onSelect,
  tooltipLabel = '',
  icon,
}) => {
  const [tool, setTool] = useRecoilState(toolButtonState);
  const selected = type === tool;

  const handleClick = useCallback(() => {
    if (onSelect) onSelect(type, icon);
    setTool((current) => (current !== type ? type : current));
  }, [onSelect, setTool, type, icon]);

  return (
    <>
      {regularBtn ? (
        <Button
          onClick={handleClick}
          startIcon={getIcon(icon)}
          disabled={disabled !== undefined ? disabled : false}
          type={(selected ? 'Selected' : 'Deselected') as any}
        >
          {tooltipLabel}
        </Button>
      ) : (
        <StyledTooltip title={tooltipLabel} placement='right' disabled={disabled}>
          <IconButton
            onClick={handleClick}
            disabled={disabled !== undefined ? disabled : false}
            type={(selected ? 'Selected' : 'Deselected') as any}
          >
            {getIcon(icon)}
          </IconButton>
        </StyledTooltip>
      )}
    </>
  );
};
