import { useTranslation } from 'react-i18next';
import { useSetRecoilState, useRecoilCallback } from 'recoil';

import { useArtefacts } from '@/modules/artefacts';
import { TemplateGroupGenerationParams } from '@/modules/common/types/shapeGroup';
import { useShapeGroup, shapeGroupState } from '@/modules/shapeGroups';
import { useTemplatingService } from '@/modules/templating/hooks/useTemplatingService';
import { TemplateType } from '@/modules/templating/types';
import { useDeleteShape } from '@/modules/workspace/hooks';
import { showNotification, NOTIFICATION_TYPES } from '@/store/recoil/notification';
import { allShapesSelector } from '@/store/recoil/shapes';
import {
  prepareToLoad as prepareToLoadConnections,
  saveLoadConnectionSelector,
} from '@modules/connections/connections';
import {
  prepareToLoad as prepareToLoadDistantConnections,
  saveLoadDistantConnectionsSelector,
} from '@modules/connections/distant';
import {
  prepareToLoad as prepareToLoadCrossings,
  saveLoadCrossingSelector,
} from '@modules/connections/crossings';
import { prepareToLoad as prepareToLoadShapes } from '@modules/shapes';

export const useTemplateGenerationCallbacks = () => {
  const { t } = useTranslation();
  const { generateTemplate } = useTemplatingService();
  const { createTemplateGroupFromShapeIds, deleteGroups } = useShapeGroup();
  const showNotificationFn = useSetRecoilState(showNotification);
  const { update: updateArtefacts } = useArtefacts();
  const { deleteMultipleShapes } = useDeleteShape();

  const createTemplate = useRecoilCallback(
    ({ snapshot, set }) =>
      async <T extends TemplateType>(
        type: T,
        input: TemplateGroupGenerationParams[T],
        templateToReplaceId?: string,
      ) => {
        const result = await generateTemplate(input);

        if (!result.success) {
          showNotificationFn({
            type: NOTIFICATION_TYPES.ERROR,
            message: t('errors:template.failed_to_generate', 'Failed to generate template.'),
          });

          return null;
        }

        // TODO: ensure that shapes fall within stage boundaries, edit them as needed. handle case when not fittable within stage

        if (templateToReplaceId) {
          const templateToReplace = await snapshot.getPromise(shapeGroupState(templateToReplaceId));
          await deleteGroups([templateToReplaceId]);
          await deleteMultipleShapes(templateToReplace.children);
        }

        const { shapes, connections, distcons, unit } = result.data;
        const shapeIdsToGroup = [];

        // TODO: remains to be seen if vehicle types and load types will be provided by template generation response.
        // For now, manually add this so the shape loading functionality can be reused for loading shapes from the template service
        const tempProvidedVehicleTypes = [];
        const tempProvidedLoadTypes = [];

        if (shapes && shapes.length > 0) {
          set(allShapesSelector, (currentShapes) => [
            ...currentShapes,
            ...prepareToLoadShapes(shapes, tempProvidedVehicleTypes, tempProvidedLoadTypes, unit),
          ]);
          shapeIdsToGroup.push(...shapes.map((item) => item.id));
        }
        if (connections && connections.length > 0) {
          set(saveLoadConnectionSelector, (currentConnections) => [
            ...currentConnections,
            ...prepareToLoadConnections(connections, shapes),
          ]);
        }
        if (distcons && distcons.length > 0) {
          set(saveLoadDistantConnectionsSelector, (currentDistcons) => [
            ...currentDistcons,
            ...prepareToLoadDistantConnections(distcons, shapes),
          ]);
        }
        set(saveLoadCrossingSelector, (currentCrossings) => [
          ...currentCrossings,
          ...prepareToLoadCrossings(shapes),
        ]);

        const group = await createTemplateGroupFromShapeIds(type, shapeIdsToGroup, input);
        await updateArtefacts(group.children);

        return group;
      },
    [
      createTemplateGroupFromShapeIds,
      deleteGroups,
      deleteMultipleShapes,
      generateTemplate,
      showNotificationFn,
      t,
      updateArtefacts,
    ],
  );

  return { createTemplate };
};
