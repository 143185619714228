import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { selector } from 'recoil';
import { allGroupIdsState, shapeGroupState } from '../atom';
import { isArea } from '../../common/helpers/shapes';
import { groupIsSingleTypeGroup } from '@/modules/common/types/shapeGroup';

export const allSingleTypeGroupIdsSelector = selector<string[]>({
  key: 'shapegroup/allAreaIds', // TODO: change
  get: ({ get }) => {
    const out = get(allGroupIdsState).filter((groupId) => {
      const group = get(shapeGroupState(groupId));

      return groupIsSingleTypeGroup(group) && isArea(group.type);
    });
    return out;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
