import { selector } from "recoil";

import { allGroupsSelector } from "@/modules/shapeGroups";
import { RECOIL_SELECTOR_CACHE_POLICY } from "@/store/recoil/common";
import { positionsAtom } from "@/store/recoil/shapes/positions/atom";

export const positionIdsNotGroupedSelector = selector<string[]>({
  key: 'position/ids/nonGrouped',
  get: ({ get }) => {
    const ids = new Set(get(positionsAtom));
    const groups = get(allGroupsSelector);
    groups.forEach((g) => {
      g.children.forEach((shapeId) => ids.delete(shapeId));
    });
    return Array.from(ids);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
