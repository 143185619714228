import { memo } from 'react';
import { Trans } from 'react-i18next';

import {
  areaMenuItems,
  buildingMenuItems,
  roadMenuItems,
  templateMenuItems,
} from '@/components/Toolbar3/constants';
import { WorkspaceMode } from '@modules/common/types/general';
import { Module } from '@modules/common/types/navigation';
import { ToolState } from '@modules/common/types/tools';
import { ShapeType } from '../../modules/common/types/shapes';
import { ToolButton } from './ToolButton';
import ToolMenu from './ToolMenu';

type props = {
  nav: Module;
  mode: WorkspaceMode;
};

export const ToolsetMain = memo(({ nav, mode }: props) => {
  if (nav !== Module.LAYOUT && nav !== Module.SALES_CANVAS) return null;

  const disabled = mode !== WorkspaceMode.EDITABLE;

  return (
    <>
      <ToolMenu disabled={disabled} items={buildingMenuItems} initialBtnIconName={ShapeType.WALL} />
      <ToolMenu disabled={disabled} items={areaMenuItems} initialBtnIconName={ShapeType.INTAKE} />
      <ToolMenu disabled={disabled} items={roadMenuItems} initialBtnIconName={ShapeType.HIGHWAY} />
      <ToolMenu disabled={disabled} items={templateMenuItems} initialBtnIconName='template' />
      <ToolButton
        type={ToolState.FLOW}
        disabled={disabled}
        tooltipLabel={
          <Trans
            i18nKey='toolbar.flow_tool.tooltip'
            ns='interface'
            components={{
              s: <span />,
            }}
          >
            Flow
          </Trans>
        }
        icon={ToolState.FLOW}
      />
      <ToolButton
        type={ToolState.DISTCON}
        disabled={disabled}
        tooltipLabel={
          <Trans
            i18nKey='toolbar.distcon_tool.tooltip'
            ns='interface'
            components={{
              s: <span />,
            }}
          >
            Distcon
          </Trans>
        }
        icon={ToolState.DISTCON}
      />
    </>
  );
});
