export default function TemplateIcon({ className = '', ...rest }) {
  return (
    <svg
      height='24px'
      width='24px'
      viewBox='0 -960 960 960'
      style={{ fill: 'currentColor', stroke: 'none' }}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...rest}
    >
      <path d='M224.62-160q-26.85 0-45.74-18.88Q160-197.77 160-224.62v-510.76q0-26.85 18.88-45.74Q197.77-800 224.62-800h510.76q26.85 0 45.74 18.88Q800-762.23 800-735.38v510.76q0 26.85-18.88 45.74Q762.23-160 735.38-160H224.62ZM420-200v-260H200v235.38q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92H420Zm40 0h275.38q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7V-460H460v260ZM200-500h560v-235.38q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H224.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7V-500Z' />
    </svg>
  );
}
