import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { drawingIdSelector } from '@/store/recoil/workspace';
import { invisibleElementsSelector } from '@modules/layers';
import { AngledHighway } from './AngledHighway';
import { angledHighwayIdsNotGroupedSelector } from '../store';

type Props = {
  saveFloorPlan: () => Promise<void>;
};

const AngledHighwaysComponent = ({ saveFloorPlan }: Props) => {
  const angledHighwayIdsNotGrouped = useRecoilValue(angledHighwayIdsNotGroupedSelector);
  const drawingId = useRecoilValue(drawingIdSelector);
  const invisibleObjects = useRecoilValue(invisibleElementsSelector);

  return (
    <>
      {angledHighwayIdsNotGrouped.length > 0 &&
        angledHighwayIdsNotGrouped
          .filter((id) => id !== drawingId && !invisibleObjects.has(id))
          .map((id) => <AngledHighway key={id} id={id} saveFloorPlan={saveFloorPlan} />)}
    </>
  );
};

export const AngledHighways = memo(AngledHighwaysComponent);
