import { selectorFamily } from "recoil";
import shapeAtom from "@/store/recoil/shape";

export const shapeTypeByIdSelector = selectorFamily({
  key: 'shape/type/byId',
  get:
    (shapeId: string) =>
    ({ get }) =>
      get(shapeAtom(shapeId))?.type,
});
