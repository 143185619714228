import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { useShapeHover } from '@modules/common/hooks/useShapeHover';
import { StorageType } from '@modules/common/types/storage';
import shapeState, { AreaShape } from '@recoil/shape';
import { ShapeProxy } from '../ShapeProxy';
import { AreaRender } from './AreaRender';
import { StorageRender } from './StorageRender';
import { Gates } from './Gates';
import { Icons } from './Icons';

export type AreaProps = {
  id: string;
};

const AreaComponent = ({ id }: AreaProps) => {
  const shape = useRecoilValue(shapeState(id)) as AreaShape;
  const { onMouseEnter, onMouseLeave } = useShapeHover(id);

  if (!shape.id) return null;

  return (
    <>
      {shape.parameters.storageType === StorageType.BLOCKSTACKING ? (
        <StorageRender
          shapeId={id}
          type={shape.type}
          isDrawing={shape.isDrawing}
          isLoading={shape.isLoading}
          x={shape.properties.x / 10}
          y={shape.properties.y / 10}
          width={shape.properties.width / 10}
          height={shape.properties.height / 10}
          r={-shape.properties.r}
        />
      ) : (
        <AreaRender
          shapeId={id}
          type={shape.type}
          isDrawing={shape.isDrawing}
          isTransforming={shape.isTransforming}
          isLoading={shape.isLoading}
          x={shape.properties.x / 10}
          y={shape.properties.y / 10}
          width={shape.properties.width / 10}
          height={shape.properties.height / 10}
          r={-shape.properties.r}
        />
      )}

      <Gates id={id} />
      <Icons id={id} />

      {/* Show Shape ID on rectangle. Handy for debugging */}
      {/* <Text {...propertiesCanvas} fontSize={80} listening={false} text={id} /> */}

      <ShapeProxy
        id={id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        x={shape.properties.x / 10}
        y={shape.properties.y / 10}
        width={shape.properties.width / 10}
        height={shape.properties.height / 10}
        angle={-shape.properties.r}
      />
    </>
  );
};

export const Area = memo(AreaComponent);
