import { memo, useEffect, useMemo, useState } from 'react';
import { Image, Line } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { Vector2 } from 'three';
import { RAD2DEG } from 'three/src/math/MathUtils';
import useImage from 'use-image';

import { areaIconAutoScaleAttr } from '@/components/Workspace/Area/consts';
import { getFlatPointsArrayFromControlPoints } from '@/modules/angledHighways';
import { theme } from '@/modules/common/components/theme';
import { useColors } from '@/modules/workspace/hooks';
import { orientedLoadCarriersBoundingBox } from '@/store/recoil/loadCarrierTypes';
import {
  supportedVehiclesLengthSelector,
  supportedVehiclesWidthSelector,
} from '@/modules/vehicles';
import { ControlPoint } from '@modules/common/types/shapes';
import { SHAPE_TO_CANVAS_SCALE } from '@modules/workspace/helpers/konva';
import { angleBetweenVectors, pointAlongVector } from '@modules/workspace/helpers/shape';

export type ProcessTwoEndPointRendererProps = {
  id: string;
  points: ControlPoint[];
  width: number;
  supportedVehicleIds: string[];
};

const ProcessTwoEndPointRendererComponent: React.FC<ProcessTwoEndPointRendererProps> = ({
  id,
  points,
  width,
  supportedVehicleIds,
}) => {
  const [arrowImage] = useImage('/chevron-double-right.svg');
  const [vehicleImage] = useImage('/arrow-down.svg');
  const { laneColor, loadBoxColor, shapeColor } = useColors(id);

  const vehicleLength = useRecoilValue(supportedVehiclesLengthSelector(supportedVehicleIds || []));
  const vehicleWidth = useRecoilValue(supportedVehiclesWidthSelector(supportedVehicleIds || []));

  const orientedLoadsBoundingBox = useRecoilValue(orientedLoadCarriersBoundingBox(id));
  const loadCarriersBoundingBox = useMemo(
    () => orientedLoadsBoundingBox,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orientedLoadsBoundingBox.length, orientedLoadsBoundingBox.width],
  );

  // local state
  const [canvasPoints, setCanvasPoints] = useState<number[]>([]);
  const [loads, setLoads] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [ends, setEnds] = useState([]);
  const [arrows, setArrows] = useState([]);
  const [directionImages, setDirectionImages] = useState([]);

  useEffect(() => {
    setCanvasPoints(
      getFlatPointsArrayFromControlPoints(points).map((num) => num * SHAPE_TO_CANVAS_SCALE),
    );
  }, [points]);

  useEffect(() => {
    const outLoads = [];
    const outVehicles = [];
    const outEnds = [];
    const outDirectionImages = [];

    if (canvasPoints.length === 0) return;

    const loadLongSide = Math.max(loadCarriersBoundingBox.length, loadCarriersBoundingBox.width);
    const loadShortSide = Math.min(loadCarriersBoundingBox.length, loadCarriersBoundingBox.width);

    const processEndPoints = [
      new Vector2(canvasPoints[0], canvasPoints[1]),
      new Vector2(canvasPoints[canvasPoints.length - 2], canvasPoints[canvasPoints.length - 1]),
    ];
    const processFirstSegmentPoints = [
      new Vector2(canvasPoints[2], canvasPoints[3]),
      new Vector2(canvasPoints[canvasPoints.length - 4], canvasPoints[canvasPoints.length - 3]),
    ];

    for (let i = 0; i < 2; i++) {
      outLoads.push({
        points: [
          ...pointAlongVector(
            processEndPoints[i],
            processFirstSegmentPoints[i],
            vehicleLength + 25 - loadLongSide,
          ),
          ...pointAlongVector(
            processEndPoints[i],
            processFirstSegmentPoints[i],
            vehicleLength + 25,
          ),
        ],
        width: loadShortSide,
        color: loadBoxColor,
        id: `load-${i}`,
      });
      outVehicles.push({
        points: [
          ...pointAlongVector(processEndPoints[i], processFirstSegmentPoints[i], 25),
          ...pointAlongVector(
            processEndPoints[i],
            processFirstSegmentPoints[i],
            vehicleLength + 25,
          ),
        ],
        width: vehicleWidth,
        color: laneColor,
        id: `vehicle-${i}`,
      });
      outEnds.push({
        points: [
          processEndPoints[i].x,
          processEndPoints[i].y,
          ...pointAlongVector(
            processEndPoints[i],
            processFirstSegmentPoints[i],
            vehicleLength + 50,
          ),
        ],
        width,
        color: shapeColor,
        id: `end-${i}`,
      });
      const centerPos = pointAlongVector(
        processEndPoints[i],
        processFirstSegmentPoints[i],
        vehicleLength / 2 + 25,
      );

      const segmentVector = new Vector2().subVectors(
        processEndPoints[i],
        processFirstSegmentPoints[i],
      );
      let angle = angleBetweenVectors(segmentVector, new Vector2(1, 0)) * RAD2DEG - 90;
      outDirectionImages.push({
        x: centerPos[0],
        y: centerPos[1],
        rotation: angle,
        id: `direction-${i}`,
      });
    }

    setLoads(outLoads);
    setVehicles(outVehicles);
    setEnds(outEnds);
    setDirectionImages(outDirectionImages);
  }, [
    canvasPoints,
    laneColor,
    loadBoxColor,
    loadCarriersBoundingBox.length,
    loadCarriersBoundingBox.width,
    shapeColor,
    vehicleLength,
    vehicleWidth,
    width,
  ]);

  // building arrows
  useEffect(() => {
    const outArrow = [];

    for (let i = 0; i < points.length - 1; i++) {
      let pointA = new Vector2(points[i].position.x, points[i].position.y).multiplyScalar(
        SHAPE_TO_CANVAS_SCALE,
      );
      let pointB = new Vector2(points[i + 1].position.x, points[i + 1].position.y).multiplyScalar(
        SHAPE_TO_CANVAS_SCALE,
      );

      if (i === 0) {
        const adjustedPointA = pointAlongVector(pointA, pointB, vehicleLength);
        pointA = new Vector2(adjustedPointA[0], adjustedPointA[1]);
      } else if (i === points.length - 2) {
        const adjustedPointB = pointAlongVector(pointB, pointA, vehicleLength);
        pointB = new Vector2(adjustedPointB[0], adjustedPointB[1]);
      }

      const segmentVector = new Vector2().subVectors(pointA, pointB);
      let angle = angleBetweenVectors(segmentVector, new Vector2(1, 0)) * RAD2DEG + 180;
      const distance = pointA.distanceTo(pointB);
      const number = Math.floor(distance / 100);

      for (let j = 1; j < number; j++) {
        const factor = j / number;
        const position = pointAlongVector(pointA, pointB, factor * distance);

        const arrow = {
          x: position[0],
          y: position[1],
          rotation: angle,
          id: `arrow-${i}-${j}`,
        };
        outArrow.push(arrow);
      }
    }

    setArrows(outArrow);
  }, [points, vehicleLength]);

  return (
    <>
      <Line
        key='line'
        points={canvasPoints}
        stroke={theme.palette.neutral.lighter}
        strokeWidth={width}
        lineCap='butt'
        listening={false}
      />

      {/* direction arrow  */}
      {arrows &&
        arrowImage &&
        arrows.map((arrow) => (
          <Image
            image={arrowImage}
            key={arrow.id}
            rotation={arrow.rotation}
            x={arrow.x}
            y={arrow.y}
            offsetX={arrowImage.width / 2}
            offsetY={arrowImage.height / 2}
            listening={false}
            strokeEnabled={false}
          />
        ))}

      {ends &&
        vehicles &&
        loads &&
        [...ends, ...vehicles, ...loads].map((object) => (
          <Line
            key={object.id}
            points={object.points}
            stroke={object.color}
            strokeWidth={object.width}
            lineCap='butt'
            listening={false}
          />
        ))}

      {vehicleImage &&
        directionImages.map((object) => (
          <Image
            key={object.id}
            image={vehicleImage}
            x={object.x}
            y={object.y}
            rotation={object.rotation}
            offsetX={vehicleImage.width / 2}
            offsetY={vehicleImage.height / 2}
            listening={false}
            strokeEnabled={false}
            {...areaIconAutoScaleAttr}
          />
        ))}
    </>
  );
};

export const ProcessTwoEndPointRenderer = memo(ProcessTwoEndPointRendererComponent);
