import shapeState, { ObstacleShape } from '@recoil/shape';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { useShapeHover } from '../../../modules/common/hooks/useShapeHover';
import { ShapeProxy } from '../ShapeProxy';
import { ObstacleRenderer } from './ObstacleRenderer';

export type ObstacleProps = {
  id: string;
};

const ObstacleComponent = ({ id }: ObstacleProps) => {
  const shape = useRecoilValue(shapeState(id)) as ObstacleShape;
  const { onMouseEnter, onMouseLeave } = useShapeHover(id);

  if (!shape.id) return null;

  return (
    <>
      <ObstacleRenderer
        shapeId={id}
        x={shape.properties.x / 10}
        y={shape.properties.y / 10}
        width={shape.properties.width / 10}
        height={shape.properties.height / 10}
        r={-shape.properties.r}
      />

      {/* Show Shape ID on rectangle. Handy for debugging */}
      {/* <Text {...propertiesCanvas} fontSize={80} listening={false} text={id} /> */}

      <ShapeProxy
        id={id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        x={shape.properties.x / 10}
        y={shape.properties.y / 10}
        width={shape.properties.width / 10}
        height={shape.properties.height / 10}
        angle={-shape.properties.r}
      />
    </>
  );
};

export const Obstacle = memo(ObstacleComponent);
