import { Connection, DTShapePersisted } from '@modules/common/types/floorPlan';
import { DistantConnection } from '@modules/common/types/connections';

import { getShapeId } from '../../common/connectionId';
import { DTShape } from '../../../common/types/shapes';
import { updateConnectionPositioning } from './connections';
import { sortConnections } from '../../common/helpers';

/**
 * Prepares distant connections state to be loaded into the app
 */
export const prepareToLoad = (
  distantConnections: Connection[],
  shapes: DTShapePersisted[],
): DistantConnection[] => {
  const shapesMap = new Map<string, DTShapePersisted>();
  shapes.forEach((item) => shapesMap.set(item.id, item));

  const connections: DistantConnection[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const distantConnection of distantConnections) {
    const fromShape = shapesMap.get(getShapeId(distantConnection.from));
    const toShape = shapesMap.get(getShapeId(distantConnection.to));
    if (!fromShape?.properties || !toShape?.properties) continue;

    const connection: DistantConnection = {
      ...distantConnection,
      toPosition: { x: 0, y: 0 },
      fromPosition: { x: 0, y: 0 },
      bubblePosition: { x: 0, y: 0 },
      rot: 0,
    };

    const position = updateConnectionPositioning(
      connection,
      fromShape as DTShape,
      toShape as DTShape,
    );
    if (position) {
      connections.push({
        ...distantConnection,
        ...position,
      });
    }
  }
  return connections;
};

/**
 * Prepares distant connections state to be saved
 */
export const prepareToSave = (distantConnections: DistantConnection[]): Connection[] => {
  const connections = distantConnections.map((distantConnection) => ({
    from: distantConnection.from,
    to: distantConnection.to,
    inChecked: distantConnection.inChecked,
    outChecked: distantConnection.outChecked,
    usePivots: distantConnection.usePivots,
    id: distantConnection.id,
  }));

  return sortConnections(connections);
};
