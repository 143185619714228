import { selector } from "recoil";

import { angledHighwayIdsState } from "@/modules/angledHighways/store/state";
import { allGroupsSelector } from "@/modules/shapeGroups";
import { RECOIL_SELECTOR_CACHE_POLICY } from "@/store/recoil/common";

export const angledHighwayIdsNotGroupedSelector = selector<string[]>({
  key: 'highway/angled/ids/nonGrouped',
  get: ({ get }) => {
    const ids = new Set(get(angledHighwayIdsState));
    const groups = get(allGroupsSelector);
    groups.forEach((g) => {
      g.children.forEach((shapeId) => ids.delete(shapeId));
    });
    return Array.from(ids);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
