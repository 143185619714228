import { memo } from 'react';
import { Rect } from 'react-konva';

import { useColors } from '@modules/workspace/hooks';

const ObstacleRendererComponent = ({
  shapeId,
  x: shapeX,
  y: shapeY,
  width: shapeWidth,
  height: shapeHeight,
  r: shapeR,
}) => {
  const { shapeColor } = useColors(shapeId);

  return (
    <Rect
      x={shapeX}
      y={shapeY}
      width={shapeWidth}
      height={shapeHeight}
      rotation={shapeR}
      fill={shapeColor}
      listening={false}
      strokeEnabled={false}
    />
  );
};

export const ObstacleRenderer = memo(ObstacleRendererComponent);
