import { supportsLoadCarriers } from '@/modules/common/helpers/shapes';
import { DTShapePersisted } from '@/modules/common/types/floorPlan';
import { groupIsSingleTypeGroup, ShapeGroup } from '@/modules/common/types/shapeGroup';
import { areaTypeToFPAreaType } from './utils';

export const createAllLoadPositionGroupGenerationSettings = (
  shapeGroups: ShapeGroup[],
  shapes: DTShapePersisted[],
  loadPositionGroupGenerationSettings,
) => {
  const usedIds = [];

  shapeGroups
    .filter(groupIsSingleTypeGroup)
    .filter((shapeGroup) => supportsLoadCarriers(shapeGroup.type))
    .forEach((shapeGroup) => {
      const children = shapes.filter((shape) => shapeGroup.children.includes(shape.id));
      AddGroupLoadPositionGroupGenerationSettings(
        loadPositionGroupGenerationSettings,
        shapeGroup,
        children,
      );
      usedIds.push(...children.map((x) => x.id));
    });

  shapes.forEach((shape) => {
    if (supportsLoadCarriers(shape.type) && !usedIds.includes(shape.id)) {
      AddAreaLoadPositionGroupGenerationSettings(loadPositionGroupGenerationSettings, shape);
      usedIds.push(shape.id);
    }
  });
};

const AddGroupLoadPositionGroupGenerationSettings = (
  loadPositionGroupGenerationSettings,
  shapeGroup,
  children,
) => {
  loadPositionGroupGenerationSettings.push({
    lpGenSettingsId: null,
    areaType: shapeGroup.type.toUpperCase(),
    discType: 'LOAD_POSITION_GROUP_BY_ADD',
    name: shapeGroup.name,
    lpgGenerationByRegex: children.map((elem) => ({
      regex: `${elem.name}_[0-9]*-[0-9]*`,
    })),
    lpgGenerationFromArea: [],
  });
};

const AddAreaLoadPositionGroupGenerationSettings = (loadPositionGroupGenerationSettings, shape) => {
  loadPositionGroupGenerationSettings.push({
    lpGenSettingsId: null,
    areaType: areaTypeToFPAreaType(shape.type),
    discType: 'LOAD_POSITION_GROUP_BY_ADD',
    name: shape.name,
    lpgGenerationByRegex: [
      {
        regex: `${shape.name}_[0-9]*-[0-9]*`,
      },
    ],
    lpgGenerationFromArea: [],
  });
};
