import { selectorFamily } from 'recoil';
import { modeSelector } from '../../../modules/common/store/workspace';
import { WorkspaceMode } from '../../../modules/common/types/general';
import { ToolState } from '../../../modules/common/types/tools';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import toolStateSelector from './toolState';
import { lockedElementSelector } from '../../../modules/layers';
import { navSelector } from '../nav';
import { Module } from '../../../modules/common/types/navigation';
import { allGroupsSelector } from '@/modules/shapeGroups';
import { ShapeGroupInteractivityMode } from '@/modules/common/types/shapeGroup';

export const draggableShapeSelector = selectorFamily<boolean, string>({
  key: 'draggableShapeSelector',
  get:
    (shapeId: string) =>
    ({ get }) => {
      const mode = get(modeSelector);
      const toolState = get(toolStateSelector);
      const isLocked = get(lockedElementSelector(shapeId));
      const nav = get(navSelector);
      const groups = get(allGroupsSelector);
      const shapeGroup = groups.find((item) => item.children.includes(shapeId));
      const memberOfSelectOnlyGroup =
        shapeGroup &&
        shapeGroup.interactivityMode === ShapeGroupInteractivityMode.LOCKED_SELECTABLE;

      if (
        nav === Module.LAYOUT &&
        !isLocked &&
        mode === WorkspaceMode.EDITABLE &&
        toolState === ToolState.SELECT &&
        !memberOfSelectOnlyGroup
      ) {
        return true;
      }

      return false;
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
