import { isAngledHighwayShape, isHighwayShape } from '@/modules/common/types/guards';
import { Crossing } from '@modules/common/types/connections';
import { DTShapePersisted } from '@modules/common/types/floorPlan';
import { DTShape } from '@recoil/shape';
import { crossingPositionsToCrossings, findCrossingPositions } from './crossings';
import { primitiveArraysHaveEqualContents } from '@/modules/common/helpers/array';

/**
 * Prepares crossings state to be loaded into the app
 */
export const prepareToLoad = (shapes: DTShapePersisted[]): Crossing[] => {
  const tempShapes = shapes.map(
    (shape): DTShape => ({
      ...shape,
      isDrawing: false,
      isTransforming: false,
      isLoading: false,
      disabled: false,
    }),
  );

  const crossings: Crossing[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < tempShapes.length; ++i) {
    const fromShape = tempShapes[i];
    if ((!isHighwayShape(fromShape) && !isAngledHighwayShape(fromShape)) || !fromShape?.properties)
      continue;
    for (let j = i + 1; j < tempShapes.length; ++j) {
      const toShape = tempShapes[j];
      if ((!isHighwayShape(toShape) && !isAngledHighwayShape(toShape)) || !toShape?.properties)
        continue;

      if (
        fromShape.parameters.supportedVehicleIds.length > 0 && 
        !primitiveArraysHaveEqualContents(fromShape.parameters.supportedVehicleIds, toShape.parameters.supportedVehicleIds)
      ) continue

      const computedCrossings = findCrossingPositions(fromShape, toShape);
      crossings.push(...crossingPositionsToCrossings(computedCrossings));
    }
  }
  return crossings;
};
