import { useMemo } from 'react';
import { useAxios } from '@/modules/api/hooks/useAxios';
import { useConfig } from '@/modules/common/hooks';

export const useTemplateApi = () => {
  const { api } = useConfig();

  const scopes = useMemo(() => [api.templateService.scope], [api.templateService.scope]);

  return useAxios({
    baseURL: api.templateService.url,
    scopes,
  });
};
