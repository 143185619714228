import { allGroupsSelector } from '@/modules/shapeGroups';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';
import { wallIdsAtom } from '@/store/recoil/shapes/wall/wallIdsAtom';

export const wallIdsNotGroupedSelector = selector<string[]>({
  key: 'wall/ids/excludeGroup',
  get: ({ get }) => {
    const ids = new Set(get(wallIdsAtom));
    const groups = get(allGroupsSelector);
    groups.forEach((g) => {
      g.children.forEach((shapeId) => ids.delete(shapeId));
    });
    return Array.from(ids);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
