import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { orientedLoadCarriersBoundingBox } from '@/store/recoil/loadCarrierTypes';
import { ObjectValues } from '@helpers/types';
import { supportsLoadCarriers } from '@modules/common/helpers/shapes';
import { AreaDirection, ShapeType } from '@modules/common/types/shapes';
import { PositionShape } from '@recoil/shape';
import {
  supportedVehiclesLengthSelector,
  supportedVehiclesWidthSelector,
} from '@/modules/vehicles';

enum PositionRenderErrorCode {
  ABSENT_LOAD_CARRIER = 'ABSENT_LOAD_CARRIER',
}

const POSITION_RENDER_ERROR = {
  [PositionRenderErrorCode.ABSENT_LOAD_CARRIER]: {
    code: PositionRenderErrorCode.ABSENT_LOAD_CARRIER,
    i18nMessageKey: 'errors:area.absent_load_carrier',
  },
} as const;

type PositionRenderErrorType = ObjectValues<typeof POSITION_RENDER_ERROR>;

export const usePositionRenderer = (shape: PositionShape) => {
  const { id: shapeId, type, parameters, properties } = shape;
  const vehicleLength = useRecoilValue(
    supportedVehiclesLengthSelector(parameters.supportedVehicleIds || []),
  );
  const vehicleWidth = useRecoilValue(
    supportedVehiclesWidthSelector(parameters.supportedVehicleIds || []),
  );
  const orientedLoadsBoundingBox = useRecoilValue(orientedLoadCarriersBoundingBox(shapeId));
  const [error, setError] = useState<PositionRenderErrorType | null>(null);

  const propertiesCanvas = {
    x: properties.x / 10,
    y: properties.y / 10,
    width: properties.width / 10,
    height: properties.height / 10,
    r: properties.r,
  };

  // Workaround for recoil currently not supporting memoized selectorFamily return values.
  const loadCarriersBoundingBox = useMemo(
    () => orientedLoadsBoundingBox,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orientedLoadsBoundingBox.length, orientedLoadsBoundingBox.width],
  );

  // if shape is not dragged, set error if no load carrier selected
  useEffect(() => {
    if (
      supportsLoadCarriers(type) &&
      (!parameters.supportedLoadCarriersIds || parameters.supportedLoadCarriersIds.length === 0)
    ) {
      setError(POSITION_RENDER_ERROR.ABSENT_LOAD_CARRIER);
    }
  }, [parameters.supportedLoadCarriersIds, type]);

  // remove absent-load-carrier error if computed loadCarriersBox is valid
  useEffect(() => {
    if (
      error &&
      error.code === POSITION_RENDER_ERROR.ABSENT_LOAD_CARRIER.code &&
      loadCarriersBoundingBox.length &&
      loadCarriersBoundingBox.width
    ) {
      setError(null);
    }
  }, [error, loadCarriersBoundingBox]);

  const vehicleBox = useMemo(() => {
    const { direction } = parameters;

    if (direction === AreaDirection.DOWN) {
      return {
        x: 0,
        y: 0,
        width: vehicleWidth,
        height: vehicleLength,
      };
    }

    if (direction === AreaDirection.UP) {
      return {
        x: 0,
        y: propertiesCanvas.height - vehicleLength,
        width: vehicleWidth,
        height: vehicleLength,
      };
    }

    if (direction === AreaDirection.RIGHT) {
      return {
        x: 0,
        y: 0,
        width: vehicleLength,
        height: vehicleWidth,
      };
    }

    if (direction === AreaDirection.LEFT) {
      return {
        x: propertiesCanvas.width - vehicleLength,
        y: 0,
        width: vehicleLength,
        height: vehicleWidth,
      };
    }
  }, [parameters, propertiesCanvas.height, propertiesCanvas.width, vehicleWidth, vehicleLength]);

  // TODO: seems too memory intensive, pull out of memo
  const loadBox = useMemo(() => {
    const { direction } = parameters;
    const diff = vehicleWidth - loadCarriersBoundingBox.length;

    if (direction === AreaDirection.DOWN) {
      return {
        x: diff / 2,
        y: 0,
        width: loadCarriersBoundingBox.length,
        height: loadCarriersBoundingBox.width,
      };
    }

    if (direction === AreaDirection.UP) {
      return {
        x: diff / 2,
        y: propertiesCanvas.height - loadCarriersBoundingBox.width,
        width: loadCarriersBoundingBox.length,
        height: loadCarriersBoundingBox.width,
      };
    }

    if (direction === AreaDirection.RIGHT) {
      return {
        x: 0,
        y: diff / 2,
        width: loadCarriersBoundingBox.width,
        height: loadCarriersBoundingBox.length,
      };
    }

    if (direction === AreaDirection.LEFT) {
      return {
        x: propertiesCanvas.width - loadCarriersBoundingBox.width,
        y: diff / 2,
        width: loadCarriersBoundingBox.width,
        height: loadCarriersBoundingBox.length,
      };
    }
  }, [
    parameters,
    vehicleWidth,
    loadCarriersBoundingBox.length,
    loadCarriersBoundingBox.width,
    propertiesCanvas.height,
    propertiesCanvas.width,
  ]);

  return {
    propertiesCanvas,
    vehicleBox,
    loadBox,
    hasLoadBox: type !== ShapeType.CHARGING_POSITION,
    error,
  };
};
