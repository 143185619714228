import { useTemplateApi } from '@/modules/api/hooks';

import { GenerationParams, GenerationResponse } from '../types';

export const useTemplatingService = () => {
  const templateServiceApi = useTemplateApi();

  const generateTemplate = async (input: GenerationParams): Promise<GenerationResponse> => {
    try {
      const response = await templateServiceApi.post('generateLassie', input);
      if (response.status !== 200) {
        console.log(`Failed to generate template: ${response.statusText}`);

        return {
          success: false,
        };
      }

      const { additionalData } = response.data;

      return {
        success: true,
        data: {
          shapes: additionalData?.shapes || [],
          connections: additionalData?.connections || [],
          distcons: additionalData?.distcons || [],
          unit: additionalData?.unit || 'mm',
        },
      };
    } catch (e) {
      console.log('Error generating template: ', e);

      return {
        success: false,
      };
    }
  };

  return {
    generateTemplate,
  };
};
