import { Area } from '@/components/Workspace/Area';
import { shapeGroupBoundingBoxSelector, shapeGroupState } from '@/modules/shapeGroups';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { Group, Rect } from 'react-konva';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ElementName, SHAPE_GROUP_INTERACTIVITY, SHAPE_TO_CANVAS_SCALE } from '../../workspace/helpers/konva';
import { hoveredShapeGroupIdState } from '../atom';
import { shapeTypeByIdSelector } from '@/store/recoil/shapes';
import { ShapeType } from '@/modules/common/types/shapes';
import { Obstacle } from '@/components/Workspace/Obstacle';
import { Position } from '@/components/Workspace/Position';
import { Road } from '@/components/Workspace/Road';
import { Wall } from '@/components/Workspace/Walls';
import { AngledHighway } from '@/modules/angledHighways/components/AngledHighway';
import { ProcessTwoEndPoint } from '@/modules/processTwoEndPoint/components/ProcessTwoEndPoint';

export const ShapeGroupComponent = ({
  id,
  globalInvisibleObjects,
  globalLockedElements,
  saveFloorPlan,
}: {
  id: string;
  globalInvisibleObjects: Set<string>;
  globalLockedElements: Set<string>;
  saveFloorPlan: (skipDebounce?: unknown) => Promise<void>;
}) => {
  const group = useRecoilValue(shapeGroupState(id));
  const setShapeGroupUnderMouseId = useSetRecoilState(hoveredShapeGroupIdState);

  const onMouseEnter = useCallback(() => {
    setShapeGroupUnderMouseId(id);
  }, [id, setShapeGroupUnderMouseId]);

  const onMouseLeave = useCallback(() => {
    setShapeGroupUnderMouseId(null);
  }, [setShapeGroupUnderMouseId]);

  useEffect(
    () => () => {
      setShapeGroupUnderMouseId((current) => (current === id ? null : current));
    },
    [setShapeGroupUnderMouseId, id],
  );

  const groupIsLockedByUser = globalLockedElements.has(id);

  const customAttrs = useMemo(() => {
    const shapeGroupInteractivityAttr = groupIsLockedByUser
      ? {}
      : {
          [SHAPE_GROUP_INTERACTIVITY]: group.interactivityMode,
        };

    return {
      ...shapeGroupInteractivityAttr,
    };
  }, [group.interactivityMode, groupIsLockedByUser]);

  const groupBoundingBox = useRecoilValue(shapeGroupBoundingBoxSelector(id));
  const childrenIdsToRender = group?.children
    ? group.children.filter((item) => !globalInvisibleObjects.has(item))
    : [];

  if (childrenIdsToRender.length === 0) return null;

  const childrenToRender = childrenIdsToRender.map((shapeId) => (
    <GroupedShape
      id={shapeId}
      key={shapeId}
      locked={globalLockedElements.has(shapeId)}
      saveFloorPlan={saveFloorPlan}
    />
  ));

  return (
    <>
      <Group
        id={id}
        name={ElementName.SHAPE_GROUP_CONTAINER}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        listening={!groupIsLockedByUser}
        {...customAttrs}
      >
        {childrenToRender}
      </Group>

      <Rect
        x={groupBoundingBox.x * SHAPE_TO_CANVAS_SCALE}
        y={groupBoundingBox.y * SHAPE_TO_CANVAS_SCALE}
        width={groupBoundingBox.width * SHAPE_TO_CANVAS_SCALE}
        height={groupBoundingBox.height * SHAPE_TO_CANVAS_SCALE}
        listening={false}
        strokeWidth={2}
        stroke='#00000040'
        shadowForStrokeEnabled={false}
        hitStrokeWidth={0}
      />
    </>
  );
};

const GroupedShape = ({ id, locked, saveFloorPlan }) => {
  const type = useRecoilValue(shapeTypeByIdSelector(id));

  switch (type) {
    case ShapeType.INTAKE:
    case ShapeType.DELIVERY:
    case ShapeType.CHARGING:
    case ShapeType.STORAGE:
    case ShapeType.PARKING:
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.HANDOVER:
      return <Area id={id} />;
    case ShapeType.PROCESS_TWO_EP:
      return <ProcessTwoEndPoint id={id} />;
    case ShapeType.INTAKE_POSITION:
    case ShapeType.DELIVERY_POSITION:
    case ShapeType.CHARGING_POSITION:
    case ShapeType.STORAGE_POSITION:
    case ShapeType.PARKING_POSITION:
    case ShapeType.PROCESS_ONE_EP_POSITION:
      return <Position id={id} />;
    case ShapeType.HIGHWAY:
      return <Road id={id} />;
    case ShapeType.HIGHWAY_ANGLED:
      return <AngledHighway id={id} saveFloorPlan={saveFloorPlan} />;
    case ShapeType.OBSTACLE:
      return <Obstacle id={id} />;
    case ShapeType.WALL:
      return <Wall id={id} />;
    default:
      return null;
  }
};

export const ShapeGroup = memo(ShapeGroupComponent);
