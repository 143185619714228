import { ShapeType } from "@/modules/common/types/shapes";
import { TemplateType } from "./types";

export const mapTemplateToolToTemplateType = (tool: ShapeType): TemplateType | null => {
  switch (tool) {
    case ShapeType.LASSIE:
      return TemplateType.LASSIE;
    default:
      return null;
  }
};
