import { memo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { NumberInput } from '@common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { CONTEXT, contextState } from '@recoil/input';

function TemplateNumberInputComponent({ value, onChange }) {
  const setContext = useSetRecoilState(contextState);
  const mode = useRecoilValue(modeSelector);

  return (
    <NumberInput
      value={value}
      sx={{ width: '100%' }}
      disabled={mode !== WorkspaceMode.EDITABLE}
      onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
      onBlur={() => setContext(CONTEXT.WORKSPACE)}
      onChange={onChange}
    />
  );
}

export const TemplateNumberInput = memo(TemplateNumberInputComponent);
