import { useRecoilValue } from 'recoil';

import { wallIdsNotGroupedSelector } from '@/store/recoil/shapes/wall/wallIdsExcludeGroupSelector';
import { drawingIdSelector } from '@/store/recoil/workspace';
import { invisibleElementsSelector } from '@modules/layers';
import { Wall } from './Wall';

export const Walls = () => {
  const wallIdsNotGrouped = useRecoilValue(wallIdsNotGroupedSelector);
  const invisibleObjects = useRecoilValue(invisibleElementsSelector);
  const drawingId = useRecoilValue(drawingIdSelector);

  return (
    <>
      {wallIdsNotGrouped
        .filter((id) => id !== drawingId && !invisibleObjects.has(id))
        .map((id) => (
          <Wall key={id} id={id} />
        ))}
    </>
  );
};
